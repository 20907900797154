<template>
  <section>
    <el-dialog
      :width="$isMobile ? '100%' : '30%'"
      :visible.sync="hasPromotion"
      @close="handleClose()"
      title="Teste"
      :fullscreen="$isMobile ? true : false"
    >
      <div slot="title">
        <h1 class="title">Indique e ganhe</h1>
      </div>
      <div>
        <h2
          style="text-align: center;"
        ><b>Para indicar alguém, entre em contato conosco pelo chat no canto inferior direito da tela.</b></h2>
      </div>
      <div class="dialog-container">
        <img class="responsive-image" src="../../assets/promotion.png" />
      </div>
    </el-dialog>
  </section>
</template>

<script>
export default {
  data() {
    return {
      hasPromotion: true
    };
  },
  methods: {
    handleClose() {
      this.hasPromotion = false;
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  text-transform: uppercase;
  font-weight: bold;
  color: $laranja;
  text-align: center;
}
.dialog-container {
  display: flex;
  margin-top: 1rem;
  justify-content: center;

  .responsive-image {
    max-height: 50vh;
    @media screen and (max-width: 767px) {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
