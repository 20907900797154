<template>
  <main>
    <indications />
  </main>
</template>

<script>
import Indications from "@/containers/Promotions/Indications";
export default {
  components: {
    Indications
  }
};
</script>

<style>
</style>
